import React, { Component } from 'react';
import Home from './Puerta';
import Politica from './Page/Acuerdo/politica';
import Terminos from './Page/Acuerdo/terminos';
import Eliminar from './Page/Acuerdo/desecho';
import ErrorInfo from './Page/ErrorInfo';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/acuerdoPolitica" component={Politica} />
        <Route exact path="/acuerdoTerminos" component={Terminos} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/borrarAbonado" component={Eliminar} />

      </Router>
    );
  }
}

export default App;
