import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import contentTitle from '../assets/imgs/contentTitle@2x.png';
import point from '../assets/imgs/point.png';
import bannerBg from '../assets/imgs/homeBg.png';



 class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    fetch( window.location.origin + '/acuerdoA',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl } = this.state;
     const subtitle = 'En sólo tres pasos, ¡puede obtener un préstamo en tan sólo 2-24 horas!'
    const desc1 = 'Registro '
    const desc2 = 'Evaluación'
    const desc3 = ' Desembolso'
     return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': 'block')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
        <div className={`content`}>
                <div>
                  <img alt='title' src={contentTitle} className={isMobile ? 'title-mobile' : 'title'}/>
                  <div className={`subtitle`}>{subtitle}</div>
                  <div className='newDesc'>
                      <div className={`desc desc1`}>{desc1}</div>
                      <div className={`line`}>{" -> "}</div>
                      <div className={`desc desc2`}>{desc2}</div>
                      <div className={`line`}>{" -> "}</div>
                      <div className={`desc desc3`}>{desc3}</div>
                  </div>  
                </div>
                {isMobile ? <img src={bannerBg} className='bannerBg' alt='bannerBg'/> : null}
               </div>
              <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className={`word-download`}>
                Descargar PagoLap
                <img src={point} className={'point'} alt='point'/>
              </a>
        </div>
      
      </Element>
    );
  }
}

export default Banner;
